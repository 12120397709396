import React, { useState, useEffect } from "react";
import axiosService from "../../utils/axios";

import locationSlice from "../../store/slices/location";
import "./Payments.css";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Cancel, CheckCircle } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import AddPayment from "../../components/add-payment/AddPayment";
import { Button } from "@mui/material";
import { useAlert } from "react-alert";
import AddInvoiceAdmin from "../../components/add-invoice/AddInvoiceAdmin";

function Payments() {
  const [chargesDevContracts, setChargesDevContracts] = useState<any[]>([]);
  const [chargesClientContracts, setChargesClientContracts] = useState<any[]>(
    []
  );
  const [chargesDevProfitShares, setChargesDevProfitShares] = useState<any[]>(
    []
  );
  const [chargesLeadRebates, setChargesLeadRebates] = useState<any[]>([]);
  const [chargesManagers, setChargesManagers] = useState<any[]>([]);
  const [chargesFounders, setChargesFounders] = useState<any[]>([]);
  const [incomingPayments, setIncomingPayments] = useState<any[]>([]);
  const [outgoingPayments, setOutgoingPayments] = useState<any[]>([]);
  const [selectedClientCharge, setSelectedClientCharge] = useState<any>({});
  const [selectedManagerCharge, setSelectedManagerCharge] = useState<any>({});
  const [selectedFounderCharge, setSelectedFounderCharge] = useState<any>({});

  const [totalChargesDevContracts, setTotalChargesDevContracts] = useState(0);
  const [totalChargesClientContracts, setTotalChargesClientContracts] =
    useState(0);
  const [totalChargesDevProfitShares, setTotalChargesDevProfitShares] =
    useState(0);
  const [paidChargesLeadRebates, setPaidChargesLeadRebates] = useState(0);
  const [paidChargesDevContracts, setPaidChargesDevContracts] = useState(0);
  const [paidChargesClientContracts, setPaidChargesClientContracts] =
    useState(0);
  const [paidChargesDevProfitShares, setPaidChargesDevProfitShares] =
    useState(0);
  const [paidChargesManagers, setPaidChargesManagers] = useState(0);
  const [paidChargesFounders, setPaidChargesFounders] = useState(0);
  const [totalChargesManagers, setTotalChargesManagers] = useState(0);
  const [totalChargesFounders, setTotalChargesFounders] = useState(0);

  const [totalChargesLeadRebates, setTotalChargesLeadRebates] = useState(0);
  const [showAddPaymentPopup, setShowAddPaymentPopup] = useState(false);
  const [showAddInvoicePopup, setShowAddInvoicePopup] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const alert = useAlert();
  useEffect(() => {
    dispatch(
      locationSlice.actions.setLocation({
        path: "/platform/payments",
      })
    );
    getCharges();
    getPayments();

    if (!auth.account) {
      navigate("/login");
    }
    if (auth.selectedView !== "GYLD MANAGER") {
      navigate("/platform");
    }
  }, []);

  const createProfitShares = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/create_charge_profit_share`)
      .then((res) => {
        alert.success("Charges Created");
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getPayments = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_incoming_payments`)
      .then((res) => {
        setIncomingPayments(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_outgoing_payments`)
      .then((res) => {
        setOutgoingPayments(res.data);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };

  const getCharges = () => {
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_dev_contracts`)
      .then((res) => {
        setChargesDevContracts(res.data);
        let total = 0;
        let paid = 0;
        res.data.map((charge: any) => {
          total += charge.net_amount;
          if (charge.status === "PAID") {
            paid += charge.net_amount;
          }
        });
        setPaidChargesDevContracts(paid);
        setTotalChargesDevContracts(total);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_client_contracts`)
      .then((res) => {
        setChargesClientContracts(res.data);
        let total = 0;
        let paid = 0;
        res.data.map((charge: any) => {
          total += charge.net_amount;
          if (charge.status === "PAID") {
            paid += charge.net_amount;
          }
        });
        setPaidChargesClientContracts(paid);

        setTotalChargesClientContracts(total);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_managers`)
      .then((res) => {
        setChargesManagers(res.data);
        let total = 0;
        let paid = 0;
        res.data.map((charge: any) => {
          total += charge.net_amount;
          if (charge.status === "PAID") {
            paid += charge.net_amount;
          }
        });
        setPaidChargesManagers(paid);

        setTotalChargesManagers(total);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_founders`)
      .then((res) => {
        setChargesFounders(res.data);
        let total = 0;
        let paid = 0;
        res.data.map((charge: any) => {
          total += charge.net_amount;
          if (charge.status === "PAID") {
            paid += charge.net_amount;
          }
        });
        setPaidChargesFounders(paid);

        setTotalChargesFounders(total);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_dev_profit_shares`)
      .then((res) => {
        setChargesDevProfitShares(res.data);
        let total = 0;
        let paid = 0;
        res.data.map((charge: any) => {
          total += charge.net_amount;
          if (charge.status === "PAID") {
            paid += charge.net_amount;
          }
        });
        setPaidChargesDevProfitShares(paid);

        setTotalChargesDevProfitShares(total);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
    axiosService
      .get(`${process.env.REACT_APP_API_URL}/get_charges_lead_rebates`)
      .then((res) => {
        setChargesLeadRebates(res.data);
        let total = 0;
        let paid = 0;
        res.data.map((charge: any) => {
          total += charge.net_amount;
          if (charge.status === "PAID") {
            paid += charge.net_amount;
          }
        });
        setPaidChargesLeadRebates(paid);
        setTotalChargesLeadRebates(total);
      })
      .catch((err) => {
        if (err.response?.status !== 500) {
          if (err.response) {
            Object.keys(err.response.data).forEach(function (key, index) {
              alert.error(key + ":" + err.response.data[key]);
            });
          }
        }

        console.log(err);
      });
  };
  const closePaymentPopup = () => {
    setShowAddPaymentPopup(false);
    getPayments();
    getCharges();
  };
  const closeAddInvoicePopup = () => {
    setShowAddInvoicePopup(false);
    getPayments();
    getCharges();
  };
  const handleAddClientInvoice = (charge: any) => {
    setSelectedClientCharge(charge);
    setShowAddInvoicePopup(true);
  };

  return (
    <div className=" container column-center">
      <div className="row-start">
        <button
          className="signUp button add-lead-button margin-right "
          onClick={() => setShowAddPaymentPopup(true)}
        >
          <span className="signUpLink">Add A Payment</span>
        </button>
        <button
          className="signUp button add-lead-button margin-right "
          onClick={createProfitShares}
        >
          <span className="signUpLink">Create Profit Shares</span>
        </button>
      </div>
      <b>
        Total Charges Incoming: {totalChargesClientContracts} € Total Charges
        Outgoing:{" "}
        {totalChargesDevContracts +
          totalChargesDevProfitShares +
          totalChargesLeadRebates}{" "}
        €{" "}
      </b>
      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">
          Charges Dev Contracts {totalChargesDevContracts} €
        </b>
        <b>Paid {paidChargesDevContracts} €</b>
        {showAddPaymentPopup && (
          <AddPayment show={showAddPaymentPopup} onClose={closePaymentPopup} />
        )}
        {selectedClientCharge && (
          <AddInvoiceAdmin
            show={showAddInvoicePopup}
            onClose={closeAddInvoicePopup}
            charge={selectedClientCharge}
          />
        )}
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Contract</TableCell>
              <TableCell align="center">Dev</TableCell>
              <TableCell align="center">Timesheet</TableCell>
              <TableCell align="center">Invoice</TableCell>
              <TableCell align="center">Ref</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargesDevContracts.map((charge: any) => (
              <TableRow
                key={charge.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {charge.id}
                </TableCell>
                <TableCell align="center">{charge.net_amount}</TableCell>
                <TableCell align="center">{charge.contract}</TableCell>
                <TableCell align="center">{charge.dev}</TableCell>
                <TableCell align="center">{charge.timesheet}</TableCell>
                <TableCell align="center">
                  {charge.invoice?.id}
                  {charge.invoice && (
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL?.split("/api")[0] +
                        charge.invoice?.file
                      }
                      download
                    >
                      <DownloadIcon style={{ color: "#626DA1" }} />
                    </a>
                  )}
                </TableCell>
                <TableCell align="center">{charge.ref}</TableCell>

                <TableCell align="center">{charge.status}</TableCell>

                <TableCell align="center">
                  {moment(charge.createdAt).format("D MMM yy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">
          Charges Client Contracts {totalChargesClientContracts} €
        </b>
        <b>Paid {paidChargesClientContracts} €</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Contract</TableCell>
              <TableCell align="center">User</TableCell>
              <TableCell align="center">Client</TableCell>
              <TableCell align="center">Timesheet</TableCell>
              <TableCell align="center">Invoice</TableCell>
              <TableCell align="center">Ref</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargesClientContracts.map((charge: any) => (
              <TableRow
                key={charge.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {charge.id}
                </TableCell>
                <TableCell align="center">{charge.net_amount}</TableCell>
                <TableCell align="center">{charge.contract}</TableCell>
                <TableCell align="center">{charge.user}</TableCell>
                <TableCell align="center">{charge.client}</TableCell>
                <TableCell align="center">{charge.timesheet}</TableCell>
                <TableCell align="center">
                  {charge.invoice?.id}
                  {charge.invoice && (
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL?.split("/api")[0] +
                        charge.invoice?.file
                      }
                      download
                    >
                      <DownloadIcon style={{ color: "#626DA1" }} />
                    </a>
                  )}
                </TableCell>
                <TableCell align="center">{charge.ref}</TableCell>
                <TableCell align="center">{charge.status}</TableCell>
                <TableCell align="center">
                  {moment(charge.createdAt).format("D MMM yy")}
                </TableCell>
                <TableCell align="center">
                  {charge.status === "NEW" && (
                    <Button onClick={() => handleAddClientInvoice(charge)}>
                      Add Invoice
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">
          Charges Dev Profit Shares {totalChargesDevProfitShares} €
        </b>
        <b>Paid {paidChargesDevProfitShares} €</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Dev</TableCell>
              <TableCell align="center">Invoice</TableCell>
              <TableCell align="center">Ref</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargesDevProfitShares.map((charge: any) => (
              <TableRow
                key={charge.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {charge.id}
                </TableCell>
                <TableCell align="center">{charge.net_amount}</TableCell>
                <TableCell align="center">{charge.dev}</TableCell>
                <TableCell align="center">
                  {charge.invoice?.id}
                  {charge.invoice && (
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL?.split("/api")[0] +
                        charge.invoice?.file
                      }
                      download
                    >
                      <DownloadIcon style={{ color: "#626DA1" }} />
                    </a>
                  )}
                </TableCell>
                <TableCell align="center">{charge.ref}</TableCell>
                <TableCell align="center">{charge.status}</TableCell>
                <TableCell align="center">
                  {moment(charge.createdAt).format("D MMM yy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">
          Charges Lead Rebates {totalChargesLeadRebates} €
        </b>
        <b>Paid {paidChargesLeadRebates} €</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">User</TableCell>
              <TableCell align="center">Timesheet</TableCell>
              <TableCell align="center">Invoice</TableCell>
              <TableCell align="center">Ref</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargesLeadRebates.map((charge: any) => (
              <TableRow
                key={charge.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {charge.id}
                </TableCell>
                <TableCell align="center">{charge.net_amount}</TableCell>

                <TableCell align="center">{charge.user}</TableCell>
                <TableCell align="center">{charge.timesheet}</TableCell>
                <TableCell align="center">
                  {charge.invoice?.id}
                  {charge.invoice && (
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL?.split("/api")[0] +
                        charge.invoice?.file
                      }
                      download
                    >
                      <DownloadIcon style={{ color: "#626DA1" }} />
                    </a>
                  )}
                </TableCell>

                <TableCell align="center">{charge.ref}</TableCell>

                <TableCell align="center">{charge.status}</TableCell>

                <TableCell align="center">
                  {moment(charge.createdAt).format("D MMM yy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">
          Charges Managers {totalChargesManagers} €
        </b>
        <b>Paid {paidChargesManagers} €</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Manager</TableCell>
              <TableCell align="center">Timesheet</TableCell>
              <TableCell align="center">Invoice</TableCell>
              <TableCell align="center">Ref</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargesManagers.map((charge: any) => (
              <TableRow
                key={charge.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {charge.id}
                </TableCell>
                <TableCell align="center">{charge.net_amount}</TableCell>

                <TableCell align="center">{charge.manager}</TableCell>
                <TableCell align="center">{charge.timesheet}</TableCell>
                <TableCell align="center">
                  {charge.invoice?.id}
                  {charge.invoice && (
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL?.split("/api")[0] +
                        charge.invoice?.file
                      }
                      download
                    >
                      <DownloadIcon style={{ color: "#626DA1" }} />
                    </a>
                  )}
                </TableCell>

                <TableCell align="center">{charge.ref}</TableCell>

                <TableCell align="center">{charge.status}</TableCell>

                <TableCell align="center">
                  {moment(charge.createdAt).format("D MMM yy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">
          Charges Founders {totalChargesFounders} €
        </b>
        <b>Paid {paidChargesFounders} €</b>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Co-Founder</TableCell>
              <TableCell align="center">Timesheet</TableCell>
              <TableCell align="center">Invoice</TableCell>
              <TableCell align="center">Ref</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chargesFounders.map((charge: any) => (
              <TableRow
                key={charge.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {charge.id}
                </TableCell>
                <TableCell align="center">{charge.net_amount}</TableCell>

                <TableCell align="center">{charge.co_founder}</TableCell>
                <TableCell align="center">{charge.timesheet}</TableCell>
                <TableCell align="center">
                  {charge.invoice?.id}
                  {charge.invoice && (
                    <a
                      target="_blank"
                      href={
                        process.env.REACT_APP_API_URL?.split("/api")[0] +
                        charge.invoice?.file
                      }
                      download
                    >
                      <DownloadIcon style={{ color: "#626DA1" }} />
                    </a>
                  )}
                </TableCell>

                <TableCell align="center">{charge.ref}</TableCell>

                <TableCell align="center">{charge.status}</TableCell>

                <TableCell align="center">
                  {moment(charge.createdAt).format("D MMM yy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Incoming Payments</b>

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Gross Amount</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Vat</TableCell>
              <TableCell align="center">From Client</TableCell>
              <TableCell align="center">Charges</TableCell>
              <TableCell align="center">Bank Transfer</TableCell>
              <TableCell align="center">Date of Bank Transfer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {incomingPayments.map((payment: any) => (
              <TableRow
                key={payment.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {payment.id}
                </TableCell>
                <TableCell align="center">{payment.gross_amount}</TableCell>
                <TableCell align="center">{payment.net_amount}</TableCell>
                <TableCell align="center">{payment.vat}</TableCell>
                <TableCell align="center">{payment.from_client}</TableCell>

                <TableCell align="center">
                  {payment.charges.map((charge: any, i: number) => {
                    return (
                      <span>
                        {charge.id +
                          (i !== payment.charges.length - 1 ? "," : "")}
                      </span>
                    );
                  })}
                </TableCell>

                <TableCell align="center">{payment.bank_transfer}</TableCell>

                <TableCell align="center">
                  {moment(payment.date_of_bank_transfer).format("D MMM yy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} className="separate-area">
        <b className="padding-normal">Outgoing Payments</b>

        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="center">Gross Amount</TableCell>
              <TableCell align="center">Net Amount</TableCell>
              <TableCell align="center">Vat</TableCell>
              <TableCell align="center">To User</TableCell>
              <TableCell align="center">Charges Contracts</TableCell>
              <TableCell align="center">Charges Leads</TableCell>
              <TableCell align="center">Charges Profit Shares</TableCell>
              <TableCell align="center">Bank Transfer</TableCell>
              <TableCell align="center">Date of Bank Transfer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outgoingPayments.map((payment: any) => (
              <TableRow
                key={payment.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {payment.id}
                </TableCell>
                <TableCell align="center">{payment.gross_amount}</TableCell>
                <TableCell align="center">{payment.net_amount}</TableCell>
                <TableCell align="center">{payment.vat}</TableCell>
                <TableCell align="center">{payment.to_user}</TableCell>

                <TableCell align="center">
                  {payment.charges_contract.map((charge: any, i: number) => {
                    return (
                      <span>
                        {charge.id +
                          (i !== payment.charges_contract.length - 1
                            ? ","
                            : "")}
                      </span>
                    );
                  })}
                </TableCell>
                <TableCell align="center">
                  {payment.charges_lead_rebate.map((charge: any, i: number) => {
                    return (
                      <span>
                        {charge.id +
                          (i !== payment.charges_contract.length - 1
                            ? ","
                            : "")}
                      </span>
                    );
                  })}
                </TableCell>
                <TableCell align="center">
                  {payment.charges_profit_share.map(
                    (charge: any, i: number) => {
                      return (
                        <span>
                          {charge.id +
                            (i !== payment.charges_contract.length - 1
                              ? ","
                              : "")}
                        </span>
                      );
                    }
                  )}
                </TableCell>

                <TableCell align="center">{payment.bank_transfer}</TableCell>

                <TableCell align="center">
                  {moment(payment.date_of_bank_transfer).format("D MMM yy")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Payments;
