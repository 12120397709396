import React, { useEffect } from "react";
import "./Developers.css";
import locationSlice from "../../store/slices/location";
import { useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-material-ui-carousel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

import { Link } from "react-router-dom";

const Developers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationSlice.actions.setLocation({ path: "/developers" }));
  }, []);

  return (
    <div className="container">
      {/* <Carousel className="carousel"> */}

      <div className="dev-row-space-between-center full-percent-width">
        <div className="dev-leftSideHeader">
          <strong className="home-title">Tired of bad projects?</strong>
          <span className="home-subtitle lightgrey padding-up-down">
            Get vetted jobs only
          </span>
          <span className="text-quicksand darkgrey text-align-left">
            The Gyld qualifies the job leads and takes the utmost care to only
            offer jobs that will resonate with the developer community. We
            filter out bad projects so you don’t have to deal with a
            disappointing work experience.
          </span>
          {/* <div className="dev-carousel-buttons">
              <button className="button-black-small text-quicksand700-small nowrap">
                I‘m invited!
              </button>
              <button className="button-white-small text-quicksand700-small nowrap">
                Keep me informed
              </button>
            </div> */}
          <button className="button-black-small text-quicksand700-small nowrap margin-t40">
            <Link className=" signUpLink" to="/contact">
              Request Sign Up
            </Link>
          </button>
        </div>
        <div className="half-percent-width row-center">
          <img
            className="dev-image1"
            src={require("../../assets/images/dev-slider2.png")}
            alt="developing vector"
          />
        </div>
      </div>

      <div className="dev-row-dots">
        <div className="dev-dots margin-right"></div>
        <div className="dev-dots margin-right"></div>
        <div className="dev-dots"></div>
      </div>

      <div className="row-reverse-space-between-center margin-b20 full-percent-width">
        <div className="dev-leftSideHeader">
          <strong className="home-title">Don’t want to feel alone?</strong>
          <span className="home-subtitle lightgrey padding-up-down">
            Get support from your peers
          </span>
          <span className="text-quicksand darkgrey text-align-left">
            All Gyld projects are managed so there is always someone you can ask
            for help. We will also take care of the contractual setup, invoicing
            and payment services and we'll mediate incase of an emergency so
            that you can concentrate on your project.
          </span>
        </div>
        <div className="half-percent-width row-center">
          <img
            className="dev-image"
            src={require("../../assets/images/dev-slider1.png")}
            alt="developing vector"
          />
        </div>
      </div>

      <div className="dev-row-space-between-center margin-b20 full-percent-width">
        <div className="dev-leftSideHeader">
          <strong className="home-title">Want better incentives?</strong>
          <span className="home-subtitle lightgrey padding-up-down">
            Profit from the Gyld’s profit share programs
          </span>
          <span className="text-quicksand darkgrey text-align-left">
            All Gyld developers automatically participate in the Gyld profit
            share program, where every quarter, a percentage of all profits will
            be shared among its active members.
          </span>
        </div>
        <div className="half-percent-width row-center">
          <img
            className="dev-image"
            src={require("../../assets/images/dev-slider3.png")}
            alt="developing vector"
          />
        </div>
      </div>

      <div className="row-reverse-space-between-center margin-b50 full-percent-width">
        <div className="dev-leftSideHeader ">
          <strong className="home-title">You want to learn a new skill?</strong>
          <span className="home-subtitle lightgrey padding-up-down">
            Join our mentorship program
          </span>
          <span className="text-quicksand darkgrey text-align-left">
            The Gyld offers working opportunities to help you master a new
            skill. We will match you with an experienced developer willing to
            help out and you can work on a project together.
          </span>
        </div>
        <div className="half-percent-width row-center">
          <img
            className="dev-image"
            src={require("../../assets/images/dev-slider4.png")}
            alt="developing vector"
          />
        </div>
      </div>
      {/* </Carousel> */}

      <div className="column-flex-start">
        {/* <div className="home-card-out">
          <div className="home-card-two">
            <div className="home-card margin-right">
              <img
                className="card-image"
                src={require("../../assets/images/blue-waves.png")}
                alt="developing vector"
              />
              <div className="home-card-inner">
                <strong className="home-card-title">
                  Take the hassle out of hiring
                </strong>
                <span className="home-card-subtitle">
                  Select the skillsets you need and get an immediate cost
                  indication. Let us know about you, your company and your
                  project to help us find the perfect candidates for your job.
                </span>
                <span className="home-card-text">
                  If the we can’t fulfil your job’s need we will let you know
                  honestly and quickly.
                </span>
                <div className="home-card-buttons">
                  <button className="button-black-big text-quicksand700">
                    Get Started
                  </button>
                </div>
              </div>
            </div>

            <div className="home-card">
              <img
                className="card-image"
                src={require("../../assets/images/blue-waves.png")}
                alt="developing vector"
              />
              <div className="home-card-inner">
                <strong className="home-card-title">
                  Handpick your developers
                </strong>
                <span className="home-card-subtitle">
                  We‘ll pre-select a short list of top notch developers that
                  will love your project (great project descriptions really
                  help!). A detailed developer profile and work portfolio that‘s
                  easy to understand & exchange, takes the pain out of the
                  decision process
                </span>
              
                <div className="home-card-buttons">
                  <button className="button-white-big text-quicksand700">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="home-card-two">
            <div className="home-card margin-right">
              <img
                className="card-image"
                src={require("../../assets/images/blue-waves.png")}
                alt="developing vector"
              />
              <div className="home-card-inner">
                <strong className="home-card-title">Operate smoothly</strong>
                <span className="home-card-subtitle">
                  Apart form the daily interactions, you can see a developer‘s
                  project feedback, risks and issues at the touch of a button in
                  the project portal. Gyld management is on call for any of your
                  needs, from escalations to quick replacement of a developer.
                </span>
            
                <div className="home-card-buttons">
                  <button className="button-black-big text-quicksand700">
                    Get Started
                  </button>
                </div>
              </div>
            </div>

            <div className="home-card">
              <img
                className="card-image"
                src={require("../../assets/images/blue-waves.png")}
                alt="developing vector"
              />
              <div className="home-card-inner">
                <strong className="home-card-title">
                  Take the hassle out of hiring
                </strong>
                <span className="home-card-subtitle">
                  Select the skillsets you need and get an immediate cost
                  indication. Let us know about you, your company and your
                  project to help us find the perfect candidates for your job.
                </span>
                <span className="home-card-text">
                  If the we can’t fulfil your job’s need we will let you know
                  honestly and quickly.
                </span>
                <div className="home-card-buttons">
                  <button className="button-white-big text-quicksand700">
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="what-gyld-dev">
          <div className="left-side-what-gyld-dev">
            <strong className="home-title-dev">What’s The Gyld?</strong>
            <div>
              <CheckCircleIcon className="green-check-icon" />
              <span className="what-subtitle">
                We are a friends of friends network
              </span>
            </div>
            <span className="what-text">
              Get only recommended & trusted developers
            </span>
            <div>
              <CheckCircleIcon className="green-check-icon" />
              <span className="what-subtitle">We aid our members</span>
            </div>
            <span className="what-text">
              Developers have mentors & support systems
            </span>
            <div>
              <CheckCircleIcon className="green-check-icon" />
              <span className="what-subtitle">We are managed</span>
            </div>
            <span className="what-text">
              Gyld managers pick up the slack & ensure smooth delivery
            </span>
            <div className="dev-carousel-buttons">
              <span className="home-text">Think it‘s a match? &nbsp;</span>
              <button className="home-text what-sign-up">Sign up now</button>
            </div>
          </div>
          <img
            className="what-gyld-image-dev"
            src={require("../../assets/images/dev-what's.png")}
            alt="developing vector"
          />
        </div> */}

        {/* <div className="row-center">
          <div className="cta">
            <img
          className="cta-image-dev"
          src={require("../../assets/images/cta-dev.png")}
          alt="developing vector"
        />
            <div className="left-side-cta">
              <strong className="home-title">Collect your finder’s fee!</strong>
              <span className="home-subtitle lightgrey">
                Participate in our Lead Share program to be rewarded a finder‘s
                fee once the lead has been converted.
              </span>
              <button className="cta-button-black-big text-quicksand700">
                Register in the lead share program
              </button>
            </div>
            <img
              className="cta-image"
              src={require("../../assets/images/img1-1.png")}
              alt="developing vector"
            />
          </div>
        </div> */}
      </div>
      {/* <div className="dev-row-dots margin-b40">
        <div className="dev-dots margin-right"></div>
        <div className="dev-dots margin-right"></div>
        <div className="dev-dots"></div>
      </div> */}

      <div className="pricing-cards margin-t40">
        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Junior</span>
            <div>
              <span className="home-title">22€</span>
              <span className="text-quicksand700">/hour</span>
            </div>
          </div>

          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Typically starting out their career
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Experience with the skill will typically be 1 year or less
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Can rely on senior support network
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Can be actively mentored by veteran developers
              </span>
            </div>
          </div>
        </div>

        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Mid</span>
            <div>
              <span className="home-title">30€</span>
              <span className="text-quicksand700">/hour</span>
            </div>
          </div>

          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Typically supported multiple clients & projects
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Skill experience will typically be 2+ years
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Is building up industry specific hot skills
              </span>
            </div>
            <div className="row-start ">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Will actively exchange with other industry and technology
                experts
              </span>
            </div>
          </div>
        </div>

        <div className="pricing-cards-out">
          <div className="pricing-cards-top">
            <span className="home-title">Senior</span>
            <div>
              <span className="home-title">37€</span>
              <span className="text-quicksand700">/hour</span>
            </div>
          </div>
          <div className="pricing-cards-bottom">
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Typically supported dozens of clients & projects
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Skill experience will typically be 5+ years
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Has one or more favorite industries
              </span>
            </div>
            <div className="row-start">
              <DoneRoundedIcon className="margin-right-small" />
              <span className="text-quicksand700">
                Is an industry and technology expert in the field
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Developers;
